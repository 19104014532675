<template>
  <Head>
    <title>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="listing">
    <div class="main-list-wrapper">
      <div class="enquire-banner">
        <div class="container-fluid p-0">
          <img
            v-if="content && content.banner_image != null"
            :src="content.banner_image"
            class="d-block layer-img"
            alt="..."
          />
          <img
            v-else
            src="../assets/VBCareers.png"
            class="d-block layer-img"
            alt="..."
          />
        </div>
      </div>
      <div class="filter-header">
        <div ref="events_head" class="container" style="max-width: 800px">
          <ul class="list-unstyled filter-list">
            <li v-if="content && content.career_title != null">
              <h5 v-html="content.career_title"></h5>
            </li>
            <li v-else>
              <h5>
                Best CBSE Coaching Classes - Tutorials In Chennai -CBSE Tuition
                In Chennai, Coaching Classes, Tutors -VBLive
              </h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="detail">
    <div class="container career-form-wrapper">
      <h6>Fill In The Below Details For Us To Support Your Career Ambitions</h6>
      <form class="row" @submit.prevent="saveCareer" ref="submitCareer">
        <div class="col-md-6">
          <label class="form-label">Student Name <span>*</span></label>
          <input
            type="text"
            placeholder="Student Name"
            class="form-control"
            v-model="name"
          />
          <div v-if="errors && errors.name" class="error-msg">
            {{ errors.name[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">School <span>*</span></label>
          <Multiselect
            v-model="schoolOptions.value"
            v-bind="schoolOptions"
            @select="isOtherSchoolOption()"
          />
          <div v-if="errors && errors.school_id" class="error-msg">
            {{ errors.school_id[0] }}
          </div>
        </div>
        <div v-if="isOtherSchool" class="col-md-6">
          <label class="form-label">School Name <span>*</span></label>
          <input
            type="text"
            placeholder="School Name"
            class="form-control"
            v-model="school_name"
          />
          <div v-if="errors && errors.school_name" class="error-msg">
            {{ errors.school_name[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Class <span>*</span></label>
          <Multiselect v-model="classOptions.value" v-bind="classOptions" />
          <div v-if="errors && errors.class_id" class="error-msg">
            {{ errors.class_id[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Email ID <span>*</span></label>
          <input
            type="email"
            placeholder="Email ID"
            class="form-control"
            v-model="email"
          />
          <div v-if="errors && errors.email" class="error-msg">
            {{ errors.email[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label for="country_code" class="form-label"
            >Country Code <span>*</span></label
          >
          <Multiselect
            v-model="countryCodeOptions.value"
            v-bind="countryCodeOptions"
          />
          <div
            v-if="errors && errors.phone_number_country_code"
            class="error-msg"
          >
            {{ errors.phone_number_country_code[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Mobile Number <span>*</span></label>
          <input
            type="text"
            placeholder="Mobile no. without country code"
            class="form-control"
            v-model="phone"
          />
          <div v-if="errors && errors.phone" class="error-msg">
            {{ errors.phone[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">
            Are You Clear With Your Career Ambition <span>*</span></label
          >
          <Multiselect
            v-model="ambitionTypeOptions.value"
            v-bind="ambitionTypeOptions"
            @select="isAmbitionCheck()"
          />
          <div v-if="errors && errors.ambition_type" class="error-msg">
            {{ errors.ambition_type[0] }}
          </div>
        </div>
        <div v-if="isAmbition" class="col-md-6">
          <label class="form-label"
            >What Is Your Career Ambition? <span>*</span></label
          >
          <Multiselect
            v-model="ambitionOptions.value"
            v-bind="ambitionOptions"
            @select="loadSpecializations(ambitionOptions.value)"
          />
          <div v-if="errors && errors.ambition_id" class="error-msg">
            {{ errors.ambition_id[0] }}
          </div>
        </div>
        <div v-if="isOtherAmbition" class="col-md-6">
          <label class="form-label">Enter Ambition <span>*</span></label>
          <input
            type="text"
            placeholder="Enter Ambition"
            class="form-control"
            v-model="ambition_name"
          />
          <div v-if="errors && errors.ambition_name" class="error-msg">
            {{ errors.ambition_name[0] }}
          </div>
        </div>
        <div v-if="isSpecializationOption" class="col-md-6">
          <label class="form-label"> Specialisation? <span>*</span></label>
          <Multiselect
            v-model="specializationOptions.value"
            v-bind="specializationOptions"
            @select="isSpecializationCheck(specializationOptions.value)"
          />
          <div v-if="errors && errors.specialization_id" class="error-msg">
            {{ errors.specialization_id[0] }}
          </div>
        </div>
        <div v-if="isOtherSpecialization" class="col-md-6">
          <label class="form-label">Enter Specialisation <span>*</span></label>
          <input
            type="text"
            placeholder="Enter Specialisation"
            class="form-control"
            v-model="specialization_name"
          />
          <div v-if="errors && errors.specialization_name" class="error-msg">
            {{ errors.specialization_name[0] }}
          </div>
        </div>
        <div v-if="isCollegeOption">
          <label class="form-label"
            >Which College Do You Want To Study In?
            <span>* </span>
            <span
              class="info-tooltip"
              data-toggle="tooltip"
              data-placement="top"
              title="Preference 1 is mandatory"
            >
              <i class="fa fa-info-circle"></i
            ></span>
          </label>
          <div class="col-md-12 mb-3" v-if="collegeOne">
            <Multiselect
              v-model="collegeOptionsOne.value"
              v-bind="collegeOptionsOne"
              @select="isSelectOptionOne()"
              @click="isClick()"
            />
          </div>
          <div class="col-md-12 mb-3" v-if="otherCollegeOne">
            <input
              type="text"
              placeholder="Enter Preference 1"
              class="form-control"
              v-model="college_name_one"
            />
          </div>
          <div class="col-md-12 mb-3" v-if="collegeTwo">
            <Multiselect
              v-model="collegeOptionsTwo.value"
              v-bind="collegeOptionsTwo"
              @select="isSelectOptionTwo()"
            />
          </div>
          <div class="col-md-12 mb-3" v-if="otherCollegeTwo">
            <input
              type="text"
              placeholder="Enter Preference 2"
              class="form-control"
              v-model="college_name_two"
            />
          </div>
          <div class="col-md-12 mb-3" v-if="collegeThree">
            <Multiselect
              v-model="collegeOptionsThree.value"
              v-bind="collegeOptionsThree"
              @select="isSelectOptionThree()"
            />
          </div>
          <div class="col-md-12 mb-3" v-if="otherCollegeThree">
            <input
              type="text"
              placeholder="Enter Preference 3"
              class="form-control"
              v-model="college_name_three"
            />
          </div>
        </div>
        <div class="col-md-12 text-center">
          <button
            type="submit"
            :disabled="isSubmitting == true"
            class="btn cta-primary"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import CommonService from "@/services/CommonService";
import CareerService from "@/services/CareerService";
import Multiselect from "@vueform/multiselect";
import { Head } from "@vueuse/head";

export default {
  name: "Career",
  components: {
    Head,
    Multiselect,
  },
  data() {
    return {
      content: null,
      name: null,
      email: null,
      phone: null,
      school_name: null,
      isOtherSchool: false,
      isAmbition: false,
      isSpecializationOption: false,
      isCollegeOption: false,
      isSubmitting: false,
      isOtherAmbition: false,
      isOtherSpecialization: false,
      ambition_name: null,
      specialization_name: null,
      schoolOptions: {
        value: null,
        options: [],
        placeholder: "Select School",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
      classOptions: {
        value: null,
        options: [],
        placeholder: "Select Class",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
      countryCodeOptions: {
        value: null,
        options: [],
        placeholder: "Select Country Code",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
      ambitionTypeOptions: {
        options: [
          {
            value: "yes",
            label: "Yes",
          },
          {
            value: "no",
            label: "No",
          },
        ],
        placeholder: "Select Any Option",
        canDeselect: false,
        searchable: true,
      },
      ambitionOptions: {
        value: null,
        options: [],
        placeholder: "Select Ambition",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
      specializationOptions: {
        value: null,
        options: [],
        placeholder: "Select Specialisation",
        canDeselect: false,
        searchable: true,
        loading: false,
      },
      collegeOptionsOne: {
        value: null,
        options: [],
        placeholder: "Preference 1",
        canDeselect: false,
        searchable: true,
        loading: false,
        // disabled: false
      },
      collegeOptionsTwo: {
        value: null,
        options: [],
        placeholder: "Preference 2",
        canDeselect: false,
        searchable: true,
        loading: false,
        // disabled: false
      },
      collegeOptionsThree: {
        value: null,
        options: [],
        placeholder: "Preference 3",
        canDeselect: false,
        searchable: true,
        loading: false,
        // disabled: false
      },
      collegeOne: true,
      collegeTwo: true,
      collegeThree: true,
      college_name_one: null,
      college_name_two: null,
      college_name_three: null,
      otherCollegeOne: false,
      otherCollegeTwo: false,
      otherCollegeThree: false,
    };
  },
  created() {
    this.fetchContent();
    this.loadSchools();
    this.loadStandards();
    this.loadCountries();
    this.loadAmbitions();
  },
  methods: {
    async fetchContent() {
      let params = {
        slug: "careers",
      };
      await CommonService.getContentPage(params)
        .then((output) => {
          if (output.data.status === "SUCCESS") {
            this.content = output.data.content;
          } else if (output.data.status == "VALIDATION_FAILED") {
            this.$toast.error(output.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadSchools() {
      await CareerService.getActiveCareerSchools()
        .then((output) => {
          this.schoolOptions.loading = false;
          if (output.data.status == "SUCCESS") {
            this.schoolOptions.options = [];
            let schools = output.data.schools;
            if (schools.length > 0) {
              schools.map((school) => {
                if (school.id != 1) {
                  let options = {
                    value: school.id,
                    label: school.school_name,
                  };
                  this.schoolOptions.options.push(options);
                }
              });
              let OtherOption = {
                value: "1",
                label: "Other",
              };
              this.schoolOptions.options.push(OtherOption);
            }
          } else {
            this.$toast.error(output.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isOtherSchoolOption() {
      if (this.schoolOptions.value == 1) {
        this.isOtherSchool = true;
      } else {
        this.isOtherSchool = false;
        this.school_name = null;
      }
    },
    async loadStandards() {
      await CareerService.getActiveClasses()
        .then((output) => {
          this.classOptions.loading = false;
          if (output.data.status == "SUCCESS") {
            this.classOptions.options = []; // to clear previously loaded options
            let standards = output.data.classes;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.class_name,
                };
                this.classOptions.options.push(options);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadCountries() {
      await CommonService.getAllCountries()
        .then((output) => {
          this.countryCodeOptions.loading = false;
          if (output.data.status == "SUCCESS") {
            this.countryCodeOptions.options = [];
            let countries = output.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.phone_code,
                  label: country.country_name + " (" + country.phone_code + ")",
                };
                this.countryCodeOptions.options.push(options);
              });
              this.countryCodeOptions.value = "+91";
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isAmbitionCheck() {
      if (this.ambitionTypeOptions.value == "yes") {
        this.isAmbition = true;
        this.ambitionOptions.value = null;
        this.isSpecializationOption = true;
        this.isCollegeOption = true;
        this.collegeOne = true;
        this.collegeTwo = true;
        this.collegeThree = true;
      } else if (this.ambitionTypeOptions.value == "no") {
        this.isAmbition = false;
        this.isSpecializationOption = false;
        this.isCollegeOption = false;
        this.collegeOne = false;
        this.collegeTwo = false;
        this.collegeThree = false;
        this.ambitionOptions.value = null;
        this.specializationOptions.value = null;
        this.collegeOptionsOne.value = null;
        this.collegeOptionsTwo.value = null;
        this.collegeOptionsThree.value = null;
        this.specializationOptions.options = [];
        this.collegeOptionsOne.options = [];
        this.collegeOptionsTwo.options = [];
        this.collegeOptionsThree.options = [];
        this.isOtherAmbition = false;
        this.isOtherSpecialization = false;
        this.ambition_name = null;
        this.specialization_name = null;
        this.otherCollegeOne = false;
        this.otherCollegeTwo = false;
        this.otherCollegeThree = false;
        this.college_name_one = null;
        this.college_name_two = null;
        this.college_name_three = null;
        this.$toast.info(
          "Our career counsellors will get in touch with you shortly once submitted"
        );
      }
    },
    async loadAmbitions() {
      await CareerService.getActiveAmbitions()
        .then((output) => {
          this.ambitionOptions.loading = false;
          if (output.data.status == "SUCCESS") {
            this.ambitionOptions.options = [];
            let ambitions = output.data.ambitions;
            if (ambitions.length > 0) {
              ambitions.map((ambition) => {
                let options = {
                  value: ambition.id,
                  label: ambition.ambition,
                };
                this.ambitionOptions.options.push(options);
              });
            }
            let otherAmbitionOption = {
              value: "0",
              label: "Other",
            };
            this.ambitionOptions.options.push(otherAmbitionOption);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadSpecializations(id) {
      if (id == "0") {
        this.isOtherAmbition = true;
        this.isOtherSpecialization = true;
        this.isSpecializationOption = false;
        this.isCollegeOption = true;
        this.collegeOne = false;
        this.collegeTwo = false;
        this.collegeThree = false;
        this.otherCollegeOne = true;
        this.otherCollegeTwo = true;
        this.otherCollegeThree = true;
      } else {
        this.isOtherAmbition = false;
        this.isOtherSpecialization = false;
        this.ambition_name = null;
        this.specialization_name = null;
        this.college_name_one = null;
        this.college_name_two = null;
        this.college_name_three = null;
        this.isSpecializationOption = true;
        this.isCollegeOption = true;
        this.collegeOne = true;
        this.collegeTwo = true;
        this.collegeThree = true;
        this.otherCollegeOne = false;
        this.otherCollegeTwo = false;
        this.otherCollegeThree = false;
        this.specializationOptions.value = null;
        this.specializationOptions.options = [];
        this.specializationOptions.loading = true;
        var params = {
          ambition_id: id,
        };
        await CareerService.getActiveSpecializations(params)
          .then((output) => {
            this.specializationOptions.loading = false;
            if (output.data.status == "SUCCESS") {
              let specializations = output.data.specializations;
              if (specializations.length > 0) {
                specializations.map((specialization) => {
                  let options = {
                    value: specialization.id,
                    label: specialization.specialization,
                  };
                  this.specializationOptions.options.push(options);
                });
              }
              let otherSpecializationOption = {
                value: "0",
                label: "Other",
              };
              this.specializationOptions.options.push(
                otherSpecializationOption
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    isSpecializationCheck() {
      if (this.specializationOptions.value == "0") {
        this.isOtherSpecialization = true;
        this.isCollegeOption = true;
        this.collegeOne = true;
        this.collegeTwo = true;
        this.collegeThree = true;
        this.otherCollegeOne = false;
        this.otherCollegeTwo = false;
        this.otherCollegeThree = false;
      } else {
        this.isOtherSpecialization = false;
        this.isCollegeOption = true;
        this.collegeOne = true;
        this.collegeTwo = true;
        this.collegeThree = true;
        this.otherCollegeOne = false;
        this.otherCollegeTwo = false;
        this.otherCollegeThree = false;
      }
      this.loadColleges(this.specializationOptions.value);
    },
    async loadColleges(id) {
      this.collegeOptionsOne.value = null;
      this.collegeOptionsOne.options = [];
      this.collegeOptionsOne.loading = true;
      this.collegeOptionsTwo.value = null;
      this.collegeOptionsTwo.options = [];
      this.collegeOptionsTwo.loading = true;
      this.collegeOptionsThree.value = null;
      this.collegeOptionsThree.options = [];
      this.collegeOptionsThree.loading = true;
      var params = {
        specialization_id: id,
      };
      await CareerService.getActiveCollegs(params)
        .then((output) => {
          this.collegeOptionsOne.loading = false;
          this.collegeOptionsTwo.loading = false;
          this.collegeOptionsThree.loading = false;
          if (output.data.status == "SUCCESS") {
            let colleges = output.data.colleges;
            if (colleges.length > 0) {
              colleges.map((college) => {
                let options = {
                  value: college.id,
                  label: college.college,
                };
                this.collegeOptionsOne.options.push(options);
              });
            }
            let otherCollegeOption = {
              value: "0",
              label: "Other",
            };
            this.collegeOptionsOne.options.push(otherCollegeOption);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isClick() {
      if (
        this.ambitionOptions.value == null ||
        this.specializationOptions.value == null
      ) {
        this.$toast.error("Please select Ambition and Specialisation");
      }
    },
    isSelectOptionOne() {
      if (this.collegeOptionsOne.value == "0") {
        this.isCollegeOption = true;
        this.collegeOne = false;
        this.otherCollegeOne = true;
        this.collegeOptionsTwo.loading = true;
        this.collegeOptionsThree.loading = true;
        this.collegeOptionsTwo.value = null;
        this.collegeOptionsThree.value = null;
        this.collegeOptionsTwo.options = [];
        this.collegeOptionsThree.options = [];
        let option_one_value = this.collegeOptionsOne.value;
        let option_one_arrays = this.collegeOptionsOne.options;
        option_one_arrays.map((option_one_array) => {
          if (option_one_value != option_one_array.value) {
            let options_two = {
              value: option_one_array.value,
              label: option_one_array.label,
            };
            this.collegeOptionsTwo.loading = false;
            this.collegeOptionsThree.loading = false;
            this.collegeOptionsTwo.options.push(options_two);
          }
        });
        let otherCollegeOption = {
          value: "0",
          label: "Other",
        };
        this.collegeOptionsTwo.options.push(otherCollegeOption);
      } else {
        this.otherCollegeOne = false;
        this.isCollegeOption = true;
        this.collegeOne = true;
        this.collegeOptionsTwo.loading = true;
        this.collegeOptionsThree.loading = true;
        this.collegeOptionsTwo.value = null;
        this.collegeOptionsThree.value = null;
        this.collegeOptionsTwo.options = [];
        this.collegeOptionsThree.options = [];
        let option_one_value = this.collegeOptionsOne.value;
        let option_one_arrays = this.collegeOptionsOne.options;
        option_one_arrays.map((option_one_array) => {
          if (option_one_value != option_one_array.value) {
            let options_two = {
              value: option_one_array.value,
              label: option_one_array.label,
            };
            this.collegeOptionsTwo.loading = false;
            this.collegeOptionsThree.loading = false;
            this.collegeOptionsTwo.options.push(options_two);
          }
        });
      }
    },
    isSelectOptionTwo() {
      if (this.collegeOptionsTwo.value == "0") {
        this.isCollegeOption = true;
        this.collegeTwo = false;
        this.otherCollegeTwo = true;
        this.collegeOptionsThree.value = null;
        this.collegeOptionsThree.options = [];
        let option_two_value = this.collegeOptionsTwo.value;
        let option_two_arrays = this.collegeOptionsTwo.options;
        option_two_arrays.map((option_two_array) => {
          if (option_two_value != option_two_array.value) {
            let options_three = {
              value: option_two_array.value,
              label: option_two_array.label,
            };
            this.collegeOptionsThree.options.push(options_three);
          }
        });
        let otherCollegeOption = {
          value: "0",
          label: "Other",
        };
        this.collegeOptionsThree.options.push(otherCollegeOption);
      } else {
        this.isCollegeOption = true;
        this.collegeTwo = true;
        this.otherCollegeTwo = false;
        this.collegeOptionsThree.value = null;
        this.collegeOptionsThree.options = [];
        let option_two_value = this.collegeOptionsTwo.value;
        let option_two_arrays = this.collegeOptionsTwo.options;
        option_two_arrays.map((option_two_array) => {
          if (option_two_value != option_two_array.value) {
            let options_three = {
              value: option_two_array.value,
              label: option_two_array.label,
            };
            this.collegeOptionsThree.options.push(options_three);
          }
        });
      }
    },
    isSelectOptionThree() {
      if (this.collegeOptionsThree.value == "0") {
        this.isCollegeOption = true;
        this.collegeThree = false;
        this.otherCollegeThree = true;
      }
    },
    async saveCareer() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.submitCareer,
      });
      let params = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        school_name: this.school_name,
        other_school_status: this.isOtherSchool,
        school_id: this.schoolOptions.value,
        class_id: this.classOptions.value,
        phone_number_country_code: this.countryCodeOptions.value,
        ambition_type: this.ambitionTypeOptions.value,
        ambition_id: this.ambitionOptions.value,
        specialization_id: this.specializationOptions.value,
        option_one: this.collegeOptionsOne.value,
        option_two: this.collegeOptionsTwo.value,
        option_three: this.collegeOptionsThree.value,
        ambition_name: this.ambition_name,
        specialization_name: this.specialization_name,
        college_name_one: this.college_name_one,
        college_name_two: this.college_name_two,
        college_name_three: this.college_name_three,
      };
      // console.log(params);
      this.isSubmitting = true;
      await CareerService.saveCareer(params)
        .then((output) => {
          loader.hide();
          this.isSubmitting = false;
          if (output.data.status == "SUCCESS") {
            this.$toast.success(output.data.message);
            this.name = null;
            this.email = null;
            this.phone = null;
            this.school_name = null;
            this.isOtherSchool = false;
            this.schoolOptions.value = null;
            this.classOptions.value = null;
            this.countryCodeOptions.value = "+91";
            this.isAmbition = false;
            this.ambitionTypeOptions.value = null;
            this.isSpecializationOption = false;
            this.isCollegeOption = false;
            this.ambitionOptions.value = null;
            this.specializationOptions.value = null;
            this.collegeOptionsOne.value = null;
            this.collegeOptionsTwo.value = null;
            this.collegeOptionsThree.value = null;
            this.ambition_name = null;
            this.specialization_name = null;
            this.specializationOptions.options = [];
            this.collegeOptionsOne.options = [];
            this.collegeOptionsTwo.options = [];
            this.collegeOptionsThree.options = [];
            this.isOtherAmbition = false;
            this.isOtherSpecialization = false;
            this.otherCollegeOne = false;
            this.otherCollegeTwo = false;
            this.otherCollegeThree = false;
            this.college_name_one = null;
            this.college_name_two = null;
            this.college_name_three = null;
            this.errors = {};
          } else if (output.data.status == "VALIDATION_FAILED") {
            this.errors = output.data.message || {};
          } else if (output.data.status == "ERROR") {
            this.$toast.error(output.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/content-page.scss";
@import "@/style/listing.scss";
</style>
