import Http from "@/services/Http";

export default {
  getActiveCareerSchools() {
    return Http().post("fetch/active/career/schools");
  },
  getActiveClasses() {
    return Http().post("fetch/active/classes");
  },
  getActiveAmbitions() {
    return Http().post("fetch/active/ambitions");
  },
  getActiveSpecializations(data) {
    return Http().post("fetch/active/specializations", data);
  },
  getActiveCollegs(data) {
    return Http().post("fetch/active/colleges", data);
  },
  saveCareer(data) {
    return Http().post("career/save", data);
  },
};
